<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("tssreq.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-2 mb-3">
            <b-col>
              <h6 class="ssp-24">{{ $t("tssreq.title") }}</h6>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-8 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('tssreq.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <select class="custom-select" v-model="filterStatus" disabled>
                  <option v-for="st in status" :key="st.id" :value="st">
                    {{ st }}
                  </option>
                </select>
              </div>
              <div class="col-md-2 pl-0">
                <input
                  type="date"
                  id="spa-sp"
                  class="form-control tgl-mulai"
                  v-model="dateStart"
                />
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <div>
                  <div>
                    <label for="spa-sp">Tanggal Mulai Bekerja</label>
                    <input
                      type="date"
                      id="spa-sp"
                      class="form-control"
                      v-model="dateStart"
                    />
                  </div>
                  <div class="mt-2">
                    <label for="spa-sp">Status</label>
                    <select class="custom-select" v-model="filterStatus">
                      <option v-for="st in status" :key="st.id" :value="st">
                        {{ st }}
                      </option>
                    </select>
                  </div>
                  <button
                    @click="resetFilter"
                    type="string"
                    class="btn btn-success w-100 mt-2 ssp-14"
                    :class="{ disabled: dateStart == '' }"
                  >
                    {{ $t("filter.reset") }}
                  </button>
                </div>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive style="overflow: hidden;">
              <b-thead class="bv-head">
                <b-tr>
                  <!-- <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th> -->
                  <b-th v-for="hd in $t('tssreq.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="6" />
              </b-tbody>
              <b-tbody v-else-if="this.totalData !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="(acc, index) in accRequests"
                  :key="acc.id"
                >
                  <!-- <b-td
                    ><input
                      type="checkbox"
                      :value="petani.id"
                      v-model="petaniIds"
                      @click="select"
                  /></b-td> -->
                  <b-td>{{ ++index + (page - 1) * perPage }}</b-td>
                  <b-td>{{ acc.name }}</b-td>
                  <b-td>{{ acc.email }}</b-td>
                  <b-td>{{ acc.phone }}</b-td>
                  <b-td>
                    <span
                      :class="{
                        'badge badge-success': acc.status,
                        'badge badge-danger': acc.status === false,
                        'badge badge-warning': acc.status === null
                      }"
                    >
                      {{
                        acc.status
                          ? "Diterima"
                          : acc.status === false
                          ? "Ditolak"
                          : $t("tssreq.label.waiting")
                      }}
                    </span>
                  </b-td>
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(acc.id)"
                      />
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="7">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select class="form-control pagination-pg" v-model="page">
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: page > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: page < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-reqsp" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <div>
          <BaseInput
            :blabel="$t('tssreq.label.name')"
            type="text"
            v-model="details.name"
            disabled
          />
          <BaseInput
            :blabel="$t('tssreq.label.email')"
            type="text"
            v-model="details.email"
            disabled
          />
          <BaseInput
            :blabel="$t('tssreq.label.phone')"
            type="text"
            v-model="details.phone"
            disabled
          />
          <BaseInput
            :blabel="$t('tssreq.label.dmc')"
            type="text"
            v-model="details.domisili"
            disabled
          />
          <BaseInput
            :blabel="$t('tssreq.label.role')"
            type="text"
            v-model="details.role"
            disabled
          />
          <BaseInput
            :blabel="$t('tssreq.label.wsd')"
            type="date"
            v-model="details.tanggalMulaiKerja"
            disabled
          />
          <BaseInput :blabel="$t('tssreq.label.wk')">
            <section v-for="wk in details.Requests" :key="wk.id">
              <table class="table table-borderless table-wk">
                <tbody>
                  <tr>
                    <td>{{ $t("tssreq.label.group") }}</td>
                    <td>:</td>
                    <td>{{ wk.group ? wk.group.group : "-" }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.country") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.country ? wk.country.country : "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.region") }}</td>
                    <td>:</td>
                    <td>{{ wk.region ? wk.region.region : "-" }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.prov") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.province ? wk.province.province : "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("tssreq.label.kab") }}</td>
                    <td>:</td>
                    <td>
                      {{ wk.kabupaten }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </BaseInput>
          <BaseInput :blabel="$t('tssreq.label.ktp')">
            <div class="detail-imgpreview">
              <img
                v-if="details.fotoKTP !== ''"
                :src="url + details.fotoKTP"
                alt="ktp"
              />
              <img
                v-else
                class="no-image"
                src="@/assets/img/no-image.png"
                alt="ktp"
              />
            </div>
          </BaseInput>
          <BaseInput :blabel="$t('tssreq.label.pp')">
            <div class="detail-imgpreview">
              <img
                v-if="details.profile !== ''"
                :src="url + details.profile"
                alt="profile"
              />
              <img
                v-else
                class="no-image"
                src="@/assets/img/no-image.png"
                alt="ktp"
              />
            </div>
          </BaseInput>
          <div class="row" v-if="details.status === null">
            <div class="col-4 pr-2">
              <button
                class="btn btn-red ssp-14 w-100 my-3 py-2"
                @click="publishAcc(details.id, false)"
              >
                {{ $t("tssreq.label.reject") }}
              </button>
            </div>
            <div class="col-8 pl-2">
              <button
                class="btn btn-primary ssp-14 w-100 my-3 py-2"
                @click="publishAcc(details.id, true)"
              >
                {{ $t("tssreq.label.approve") }}
              </button>
            </div>
          </div>
        </div>
        <!-- <p class="">Modal Body with button</p> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card, BaseInput } from "@/components/index";
import _ from "lodash";
import JwtService from "@/core/services/jwt.service";
import { RoleType, statusAccountRequest } from "@/graphql/enum.type.js";
// import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { detailAccountRequest, listAccountRequest } from "@/graphql/queries";
import { publishAccountRequest } from "@/graphql/mutations";

export default {
  components: {
    Card,
    BaseInput
    // "custom-select": Multiselect
  },
  data() {
    return {
      loading: true,
      dataPetani: [],
      accRequests: [],
      dataSP: [],
      dataTSS: [],
      url: JwtService.getURL(),
      optionRole: [RoleType.TSS, RoleType.SP],
      status: [
        // statusAccountRequest.APPROVE,
        statusAccountRequest.PENDING
        // statusAccountRequest.DECLINE
      ],

      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      provID: [],

      totalPages: null,
      page: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterStatus: statusAccountRequest.PENDING,
      dateStart: "",
      filterSP: [],
      searchSP: "",
      searchTSS: "",

      modalTitle: null,
      isDisabled: null,
      isDetail: null,

      tssID: JwtService.getID()
    };
  },
  apollo: {
    listRequestAccount: {
      query: () => listAccountRequest,
      variables() {
        return {
          page: this.page,
          limit: this.perPage,
          keyword: this.filterInput,
          tssId: "",
          tipe: this.optionRole[0],
          status: this.filterStatus,
          tanggalMulaiBekerja: this.dateStart,
          orderBy: "CREATED_AT",
          sortBy: "DESC"
        };
      },
      fetchPolicy: "network-only",
      debounce: 500,
      result({ data }) {
        this.accRequests = data.listRequestAccount.requestAccounts;
        this.page = data.listRequestAccount.page;
        this.perPage = data.listRequestAccount.limit;
        this.totalData = data.listRequestAccount.total;
        this.totalPages = data.listRequestAccount.totalPage;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    }
  },
  methods: {
    resetFilter() {
      this.dateStart = "";
    },
    setPage() {
      this.page = 1;
    },
    addPage() {
      if (this.page < this.totalPages) {
        this.page++;
      }
    },
    redcPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    onDetail(id) {
      this.resetForm();
      return new Promise((resolve, reject) => {
        try {
          this.$apollo
            .query({
              query: detailAccountRequest,
              variables: {
                id: id
              },
              fetchPolicy: "no-cache"
            })
            .then(res => {
              this.details = res.data.detailRequestAccount;
              const kabGrouped = _(res.data.detailRequestAccount.Requests)
                .groupBy("province_id")
                .map(function(kabs, key) {
                  return {
                    province_id: key,
                    kabupatens: kabs.map(a => {
                      return a.kabupaten;
                    })
                  };
                })
                .value();

              const detail = [];
              res.data.detailRequestAccount.Requests.map(a => {
                const ob = {};
                if (a.countrys) {
                  ob.countrys = a.countrys;
                }
                if (a.regions) {
                  ob.regions = a.regions;
                }
                if (a.province) {
                  ob.province = a.province;
                }
                if (a.kabupaten) {
                  ob.kabupaten = [];
                }
                if (a.groups) {
                  ob.groups = a.groups;
                }
                detail.push(ob);
              });

              const rst = [];
              detail.map(a => {
                kabGrouped.map(b => {
                  if (b.province_id === a.province.id) {
                    rst.push({
                      country: a.countrys,
                      region: a.regions,
                      province: a.province,
                      kabupaten: b.kabupatens
                        .map(obj => obj.kabupaten)
                        .join(", "),
                      group: a.groups
                    });
                  }
                });
              });
              this.details.Requests = _.uniqBy(rst, "province_id");

              this.isDisabled = true;
              this.isDetail = true;
              if (this.$i18n.locale === "en") {
                this.modalTitle = `${this.details.name} details`;
              } else if (this.$i18n.locale === "id") {
                this.modalTitle = `Detail ${this.details.name}`;
              }
              this.$bvModal.show("modal-reqsp");
              resolve();
            });
        } catch (e) {
          this.errorAlert(e);
          reject(e);
        }
      });
    },
    async publishAcc(id, status) {
      if (status) {
        return new Promise((resolve, reject) => {
          const publishAcc = this.$apollo.mutate({
            mutation: publishAccountRequest,
            variables: {
              id: id,
              status: true
            }
          });
          if (resolve) {
            resolve(publishAcc);
          } else {
            reject(publishAcc);
          }
        })
          .then(response => {
            if (response) {
              this.$apollo.queries.listRequestAccount.refetch();
              this.toastAlert("Berhasil approve TSS");
              this.closeModalTambah();
            }
          })
          .catch(e => {
            this.errorAlert(e);
          });
      } else {
        return new Promise((resolve, reject) => {
          const publishAcc = this.$apollo.mutate({
            mutation: publishAccountRequest,
            variables: {
              id: id,
              status: false
            }
          });
          if (resolve) {
            resolve(publishAcc);
          } else {
            reject(publishAcc);
          }
        })
          .then(response => {
            if (response) {
              this.$apollo.queries.listRequestAccount.refetch();
              this.toastAlert("Berhasil approve TSS");
              this.closeModalTambah();
            }
          })
          .catch(e => {
            this.errorAlert(e);
          });
      }
    },
    resetForm() {
      this.details = {};
    },
    closeModalTambah() {
      this.$bvModal.hide("modal-reqsp");
      this.resetForm();
    },
    selectAll() {
      this.petaniIds = [];
      if (!this.allSelected) {
        for (let i in this.dataPetani) {
          this.petaniIds.push(this.dataPetani[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    }
  },
  mounted() {
    // this.filterStatus = this.status[0];
  }
};
</script>

<style>
.table-wk tbody tr:first-child {
  border-top: 1px solid #d1d1d1;
}
.table-wk tbody tr:last-child {
  border-bottom: 1px solid #d1d1d1;
}
.table-wk tbody tr td {
  padding: 4px;
}
.table-wk tbody tr td:first-child {
  width: 30%;
}
.table-wk tbody tr td:nth-child(2) {
  width: 6%;
}
.custom-select,
.tgl-mulai {
  background-color: #fff;
}
</style>
